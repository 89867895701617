import { navigate } from "gatsby-link"
import React, { useMemo, useState } from "react"
import { useApp } from "../../hooks/useApp"
import { useFunctions } from "../../hooks/useFunctions"
import { useKlaviyo } from "../../hooks/useKlaviyo"
import { useAnalytics } from "../../hooks/useAnalytics"

let feedback = '', rt = 0;

export const withMentorFeedback = Component =>
  React.memo(({ name="withMentorFeedback", mentor }) => {
    const { member, unReviewMentors, setUnReviewedMentors } = useApp()
    const { requestUpdate } = useFunctions()
    const { trackCustomEvent } = useAnalytics()
    const { trackEvent } = useKlaviyo()
    const [ selectedRating, setSelectedRating ] = useState(0)
    const [ loading, setLoading ] = useState(false)

    Component.displayName = name

    const handleSubmit = async () => {
      const payload = {
        feedback: feedback,
        rating: rt
      }
      setLoading(true)
      const selectRequest = unReviewMentors?.find(item => item.mentor._id == mentor.id)
      await requestUpdate({
        id: selectRequest?._id,
        data: {
          feedback: payload,
          status: "read"
         },
      })

      if (member?.contact?.email) {
        trackCustomEvent("member_feedback", {
          member_id: member?._id,
          feedback_score: rt,
          comments: feedback,
        })
      }

      trackEvent("Mentor Feedback", {
        mentor: mentor,
        mentee: selectRequest?.mentee,
        feedback: feedback,
        rating: rt
       })

      const updatedMentors = unReviewMentors?.filter(item => item._id != selectRequest?._id)
      setUnReviewedMentors(updatedMentors)
      setLoading(false)

      navigate("/mentors/feedback-complete")
    }

    const handleOnSelect = ({ rating, value }) => {
      if (rating) {
        rt = rating
      }
      if (value) {
        feedback = value
      }
      setSelectedRating(rating)
    }

    return useMemo(
      () => (
        <Component handleSubmit={handleSubmit} mentor={mentor} handleOnSelect={handleOnSelect} selectedRating={selectedRating} loading={loading} />
    ), [mentor, loading, selectedRating])
  })
